<template>
  <div class="list">
    <div class="listBanner">
      <img class="img_c1" src="../assets/img/temp/banner_news.jpg" alt="">
    </div>
    <div class="listMain wrap">
      <el-row :gutter="30">
        <el-col :span="5">
          <div class="listSlid">
            <div class="listSlidHd">
              <h3>{{slideHd.title}}</h3>
              <p>{{slideHd.sub}}</p>
            </div>
            <div class="listSlidList">
              <div class="listSlidListInner" :class="{on : active == index}" v-for="(item, index) in slideList" :key="index">
                <a :href="`${apiName}/news?t=${index}`">{{item.title}}</a>
                <span></span>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="19">
          <div class="listWrap">
            <div class="listHd listHdPage clearfloat">
              <div class="fl searchHd"><span>搜索关键词：<em>{{pageTitle}}</em></span> <span>共 <em>{{total}}</em> 条</span></div>
              <div class="fr listHdGrop"><a href="/">首页</a> · <a href="">{{slideHd.title}}</a> </div>
            </div>
            <div class="listContainer">
              <ul class="listList">
                <li v-for="(item, index) in listData" :key="index">
                  <a class="clearfloat" target="_blank" :href="!item.is_link || item.is_link === 0 ? `${apiName}/detail?id=${item.id}` : item.link_url">
                    <div class="time fr">
                      <h3>{{dayjs(item.create_time).format('MM-DD')}}</h3>
                      <p>{{dayjs(item.create_time).format('YYYY')}}</p>
                    </div>
                    <div class="inner single-line">
                      {{item.title}}
                    </div>
                  </a>
                </li>
              </ul>
              <div class="listPage">
                <el-pagination
                  background
                  layout="prev, pager, next"
                  :current-page="pageNum"
                  :page-size="pageSize"
                  :total="total"
                  @current-change="currentChange">
                </el-pagination>
              </div>
              <!-- <detail-page ref="listPage" :gropId="gropId" v-if="pageType == 1"></detail-page>
              <list-page ref="listPage" :gropId="gropId" v-if="pageType == 2"></list-page>
              <list-img ref="listPage" :gropId="gropId" v-if="pageType == 3"></list-img>
              <list-video ref="listPage" :gropId="gropId" v-if="pageType == 4"></list-video> -->
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
// import listPage from '@/components/list.vue'
// import detailPage from '@/components/detail.vue'
// import listImg from '@/components/listImg.vue'
// import listVideo from '@/components/listVideo.vue'
export default {
  components: {
    // listPage,
    // detailPage,
    // listImg,
    // listVideo
  },
  name: 'list',
  data(){
    return {
      pageTitle: '',
      pageType: 2,
      gropId: '',
      hdTitle: '',
      pageCont: '',
      active: 0,
      slideHd: {
        title: '搜索结果',
        sub: 'SEARCH',
      },
      // type 1: 文章；2：列表；3：图片列表；4：视频列表；
      slideList:[],
      pageSize: 20,
      pageNum: 1,
      total: 0,
      listData: []
    }
  },
  mounted(){
    var _this = this
    _this.pageTitle = _this.$route.query.title
    _this.getList()
  },
  methods: {
    async getList() {
      var _this = this;
      var { data } = await _this.$http.post(_this.apiUrl + '/news/getNewsList', 
        {
          company_id: _this.companyId, 
          title: _this.pageTitle, 
          page: _this.pageNum, 
          pageSize: _this.pageSize
        }
      );
      _this.total = data.data.total
      _this.listData = data.data.list;
    },
    currentChange(val) {
      var _this = this
      _this.pageNum = val
      _this.getList()
    }
  }
}
</script>